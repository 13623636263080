import { Link, useLocation } from "react-router-dom";
import "./Topnav.css";
import { useState, useEffect } from "react";

const Topnav = ({ setOpen }: { setOpen?: any }) => {
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState(location.pathname);

  useEffect(() => {
    setSelectedMenu(location.pathname);
  }, [location.pathname]);

  return (
    <div className="topnav-wrap">
      <div className="topnav-inner">
        <Link to="/">
          <img
            src="/asset/images/logo/header-logo.svg"
            alt="Logo"
            style={{ height: 20 }}
          />
        </Link>

        <div className="topnav-right">
          <div className="topnav-right-inner">
            <div className="tab-menu-box">
              <Link
                to="/"
                className={`tab-menu ${selectedMenu === "/" ? "selected" : ""}`}
                onClick={() => setSelectedMenu("/")}
              >
                Home
              </Link>
              <Link
                to="/howwedo"
                className={`tab-menu ${selectedMenu === "/howwedo" ? "selected" : ""}`}
                onClick={() => setSelectedMenu("/howwedo")}
              >
                How We Do
              </Link>
              <Link
                to="/aimarket"
                className={`tab-menu ${selectedMenu === "/aimarket" ? "selected" : ""}`}
                onClick={() => setSelectedMenu("/aimarket")}
              >
                AI Market
              </Link>
              <Link
                to="/team"
                className={`tab-menu ${selectedMenu === "/team" ? "selected" : ""}`}
                onClick={() => setSelectedMenu("/team")}
              >
                Team
              </Link>
            </div>
            <button className="contact-button" onClick={() => setOpen(true)}>
              상담신청
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topnav;
