import { useState } from "react";
import { keywordCardData, keywords } from "./data";
import "./AiSection.css";

type Keyword = string;

const Section03 = () => {
  const [selectedKeywords, setSelectedKeywords] = useState<Keyword[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  const ITEMS_PER_PAGE = 6;

  const toggleKeyword = (keyword: Keyword) => {
    if (selectedKeywords.includes(keyword)) {
      setSelectedKeywords(selectedKeywords.filter((kw) => kw !== keyword));
    } else {
      setSelectedKeywords([...selectedKeywords, keyword]);
    }
    setCurrentPage(0);
  };

  const filteredCards =
    selectedKeywords.length === 0
      ? keywordCardData
      : keywordCardData.filter((card) =>
          selectedKeywords.some((keyword) => card.keywords.includes(keyword)),
        );

  const totalPages = Math.ceil(filteredCards.length / ITEMS_PER_PAGE);

  const displayedCards = filteredCards.slice(
    currentPage * ITEMS_PER_PAGE,
    (currentPage + 1) * ITEMS_PER_PAGE,
  );

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="ai-section-wrap-bg-gradient">
      <div className="ai-section-inner-p112">
        <div className="section-title-box">
          <div className="section-title-text" style={{ color: "#fff" }}>
            Solution Search by Keywords
          </div>
          <div className="section-description-text" style={{ color: "#fff" }}>
            키워드로 찾는 솔루션
          </div>
        </div>
        <div className="section-content-box">
          <div className="keywords-box">
            {keywords.map((keyword, index) => (
              <button
                className={`keyword ${
                  selectedKeywords.includes(keyword) ? "selectedKeyword" : ""
                }`}
                key={index}
                onClick={() => toggleKeyword(keyword)}
              >
                {keyword}
              </button>
            ))}
          </div>
        </div>
        <div className="section-content-box">
          <div className="keyword-design">
            <div className="keyword-pagination-box">
              <div className="paging-number-box">
                {Array.from({ length: totalPages }, (_, index) => (
                  <span
                    key={index}
                    className={`page-number ${
                      index === currentPage ? "current-page" : ""
                    }`}
                    onClick={() => setCurrentPage(index)}
                  >
                    {index + 1}
                  </span>
                ))}
              </div>
              <div className="control-btn-box">
                <img
                  src="/asset/images/arrow/arrow-white-circle-left.svg"
                  alt="Previous Page"
                  onClick={handlePrevPage}
                  style={{
                    cursor: currentPage > 0 ? "pointer" : "not-allowed",
                    opacity: currentPage > 0 ? 1 : 0.5,
                  }}
                />
                <img
                  src="/asset/images/arrow/arrow-white-circle-right.svg"
                  alt="Next Page"
                  onClick={handleNextPage}
                  style={{
                    cursor:
                      currentPage < totalPages - 1 ? "pointer" : "not-allowed",
                    opacity: currentPage < totalPages - 1 ? 1 : 0.5,
                  }}
                />
              </div>
            </div>
            {displayedCards.map((card, index) => (
              <div className="keyword-card" key={index}>
                <div className="keyword-card-top">
                  <div className="duration">{card.duration}</div>
                  <div className="keyword-title">{card.title}</div>
                  <div
                    className="keyword-des"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {card.description}
                  </div>
                </div>
                <div className="keyword-card-img-box">
                  <img src={card.imgSrc} alt="" />
                  <div style={{ whiteSpace: "pre-line" }}>
                    {card.imgDiscrption}
                  </div>
                </div>
                <div className="keywords-box">
                  {card.keywords.map((keyword, index) => (
                    <span
                      className={
                        keyword === card.blueKeyword
                          ? "keywordblue"
                          : "keywordgray"
                      }
                      key={index}
                    >
                      {keyword}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section03;
