import ScrollToTopButton from "../toTopBtn/ToTopButton";

const FloatingBtnBox = ({ setOpen }: { setOpen: any }) => {
  return (
    <div
      style={{
        position: "fixed",
        right: "56px",
        bottom: "56px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        gap: "24px",
      }}
    >
      <ScrollToTopButton />
    </div>
  );
};
export default FloatingBtnBox;
