import { useEffect, useState } from "react";
import InquiryBtn from "../../../../components/inquiryBtn/InquiryBtn";
import "./Section01.css";

const Section01 = ({
  setOpen,
  onScrollPast,
}: {
  setOpen: any;
  onScrollPast: any;
}) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 0;
      setIsScrolled(scrolled);
      onScrollPast(scrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [onScrollPast]);

  return (
    <div className="main-banner">
      <div className="text-area">
        <div className="div">
          쉽고, 빠르고, 합리적으로
          <br />
          솔루션을 개발합니다.{" "}
        </div>
        <div className="text">
          <div className="problem-solving">Problem Solving </div>
          <div className="ai-big-data-blockchain-hecto-labs">
            AI, Big Data, Blockchain 도입이 고민이시라면,
            <br />
            Hecto Labs가 빠르고, 합리적으로 만들어드립니다.
            <br />
            아이디어만 가져오세요!{" "}
          </div>
        </div>
      </div>
      {!isScrolled && (
        <InquiryBtn
          setOpen={setOpen}
          bottom={56}
          position="absolute"
          boxShadow={false}
        />
      )}
    </div>
  );
};

export default Section01;
