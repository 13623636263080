import "./Update.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef } from "react";

const cardData = [
  {
    category: "문의",
    text: "AI기반 지식재산권 유사도 판단 서비스 MVP 개발",
    tag: "#지식재산권",
  },
  {
    category: "문의",
    text: "Big Data 및 AI 기술을 활용한 신재생에너지 발전 사업 타당성 분석 서비스 개발",
    tag: "#에너지",
  },
  {
    category: "문의",
    text: "금융권 토지 및 농지 부동산 중개 서비스 개발",
    tag: "#부동산 중개",
  },
  {
    category: "강의",
    text: "2024년도 인공지능(AI)·빅데이터를 활용한 신약개발 전략교육(전문과정) - 신연성 이사",
    tag: "#의학",
  },
  {
    category: "수상",
    text: "한국신약개발연구조합 인공지능(AI)·빅데이터를 활용한 신약개발 유공자 공로패 - 김기현 대표",
    tag: "#의학",
  },
  {
    category: "문의",
    text: "위치기반 탄소발자국 측정 서비스",
    tag: "#탄소",
  },
  {
    category: "문의",
    text: "AI기반 영상 편집 솔루션",
    tag: "#영상",
  },
  {
    category: "문의",
    text: "B2B 탄소회계 관리 솔루션",
    tag: "#탄소",
  },
  {
    category: "문의",
    text: "AI 음성인식 솔루션",
    tag: "#음성",
  },
  {
    category: "문의",
    text: "케이터링 디자인 AI 자동화 솔루션",
    tag: "#케이터링",
  },
];

const Update = () => {
  const sliderRef = useRef<Slider | null>(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 2500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    centerMode: false,
    centerPadding: "16px",
  };

  // Function to go to the next slide
  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  // Function to go to the previous slide
  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <div className="update-container">
      <div className="update-inner">
        <div className="update-title">Update</div>
        <Slider ref={sliderRef} {...settings}>
          {cardData.map((card, index) => (
            <div className="update-slide-card" key={index}>
              <span className="update-card-category">{card.category}</span>
              <div className="update-card-text">{card.text}</div>
              <div className="update-card-tag">{card.tag}</div>
            </div>
          ))}
        </Slider>
        <div className="slider-btn-box">
          <img
            src="/asset/images/arrow/arrow-left-circle-gray.svg"
            alt="Previous"
            onClick={prevSlide}
          />
          <img
            src="/asset/images/arrow/arrow-right-circle-gray.svg"
            alt="Next"
            onClick={nextSlide}
          />
        </div>
      </div>
    </div>
  );
};

export default Update;
