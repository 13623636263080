const ScrollToTopButton = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      style={{
        cursor: "pointer",
      }}
    >
      <img src="/asset/images/arrow/totop.svg" alt="" onClick={scrollToTop} />
    </div>
  );
};

export default ScrollToTopButton;
