import React from "react";
import "./Section.css";

const Section03 = () => {
  return (
    <div className="how-section-wrap-bg-lightblue">
      <div className="how-section02-inner">
        <div className="section-title-box">
          <div className="section-title-text">Solution Design Process</div>
          <div className="section-description-text">
            문제 해결의 답은 고객에게 있습니다. <br />
            고객의 요구사항을 철저히 파악하는 데서 출발합니다.
          </div>
        </div>
        <div className="section-content-box">
          <div className="solution-design-process">
            {cardData.map((card, index) => (
              <React.Fragment key={index}>
                <div className="solution-design-process-card">
                  <div className="s3-process-card-top">
                    <span className="s3-process-number">{card.number}</span>
                    <span className="s3-process-tag">{card.tag}</span>
                  </div>
                  <div className="s3-process-card-bottom">
                    <img src={card.imgSrc} alt="" />
                    <div className="s3-process-card-bottom-textWrap">
                      <div className="s3-process-card-bottom-title">
                        {card.title}
                      </div>
                      <div className="s3-process-card-bottom-list">
                        {card.bottomTexts.map((text: any, idx: number) => (
                          <div className="s3-process-card-bottom-li" key={idx}>
                            <img
                              src="/asset/images/How/list-dot.svg"
                              alt=""
                              className="list-dot"
                            />
                            <div className="s3-process-card-bottom-list-text">
                              {text}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {index !== 3 && index !== 7 && (
                  <img src="/asset/images/icon/right-arrow.svg" alt="" />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Section03;

const cardData = [
  {
    number: "01",
    tag: "개발전",
    title: "고객과의 상담",
    imgSrc: "/asset/images/How/addperson.svg",
    bottomTexts: ["업무 프로세스 및 과제 도출", "활용 가능 영역 특정"],
  },
  {
    number: "02",
    tag: "개발전",
    title: "솔루션 설계",
    imgSrc: "/asset/images/How/solution.svg",
    bottomTexts: [
      <>
        과제 해결을 위한 <br /> 프로젝트 플랜 검토
      </>,
    ],
  },
  {
    number: "03",
    tag: "개발전",
    title: "AI 알고리즘 검토",
    imgSrc: "/asset/images/How/algolism.svg",
    bottomTexts: [
      <>
        과업 내용 및 난이도에 따른 <br /> 알고리즘 검토 및 설계 제안
      </>,
    ],
  },
  {
    number: "04",
    tag: "개발전",
    title: "프로젝트 계획 수립",
    imgSrc: "/asset/images/How/schema.svg",
    bottomTexts: [
      <>
        최종 목표에서 역산한 <br />
        개발계획 책정
      </>,
    ],
  },

  {
    number: "05",
    tag: "개발",
    title: "필요 데이터 수집",
    imgSrc: "/asset/images/How/data.svg",
    bottomTexts: [
      <>
        사용 데이터 수집 및 <br /> 머신러닝 등 학습
      </>,
    ],
  },
  {
    number: "06",
    tag: "개발",
    title: "모델 개발",
    imgSrc: "/asset/images/How/code.svg",
    bottomTexts: ["모델 개발 및 개량"],
  },
  {
    number: "07",
    tag: "개발",
    title: "정밀도 개선 및 PoC",
    imgSrc: "/asset/images/How/target.svg",
    bottomTexts: ["테스트 및 정밀도 개선"],
  },
  {
    number: "08",
    tag: "개발 후",
    title: "실제 적용 및 지원",
    imgSrc: "/asset/images/How/setting.svg",
    bottomTexts: ["실제 운영 및 가동 후의 A/S 지원"],
  },
];
