import "./Section02.css";

const Section02 = () => {
  return (
    <div className="section-wrap">
      <div className="section-1-inner">
        <div className="title">
          <div className="reasons">Reasons </div>
          <div className="div">왜 헥토랩스 일까요? </div>
        </div>
        <div className="contents">
          <div className="_1">
            <img src="/asset/images/Main/pencil.svg" alt="" />
            <div className="text">
              <div className="headline">End-to-End 개발 </div>
              <div className="div2">
                제목만 있어도 됩니다.
                <br />
                글로벌 컨설팅펌 출신 전문가들이 <br />
                기획에서 개발까지 맞춤형으로 만들어드립니다.{" "}
              </div>
            </div>
          </div>
          <div className="line-6"></div>
          <div className="_1">
            <img src="/asset/images/Main/calander.svg" alt="" />
            <div className="text">
              <div className="headline">Fast 개발 </div>
              <div className="div2">
                당면한 문제 해결을 위해 미팅을 요청하시면
                <br />
                개발 방향의 답을 바로 찾아드립니다.
                <br />
                PoC/MVP 결과물은 1개월이면 가능합니다.{" "}
              </div>
            </div>
          </div>
          <div className="line-6"></div>
          <div className="_1">
            <img src="/asset/images/Main/money.svg" alt="" />
            <div className="text">
              <div className="headline">Reasonable 개발</div>
              <div className="div2">
                가장 빠른 시간 내에
                <br />
                최소 투입으로 최대의 성과를 만듭니다.
                <br />
                가장 합리적인 개발 비용을 제시합니다.{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Section02;
