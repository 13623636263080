export const keywords = [
  "거래소",
  "기준정보",
  "규제",
  "건축 설계",
  "검색 분류",
  "검색엔진",
  "견적",
  "경공매",
  "국회 의안",
  "긍정/부정",
  "네트워크",
  "대출 가능성",
  "데이터 수집",
  "데이터 학습",
  "딥러닝",
  "보안관제",
  "보이스피싱",
  "보증서",
  "부동산",
  "부동산 가격",
  "부실 채권",
  "블록체인",
  "빅데이터",
  "알고리즘",
  "암호화폐",
  "일일 결산",
  "예상 가격",
  "예상 연금",
  "자동 분류",
  "자연어",
  "작물 추천",
  "정책",
  "탈중앙거래소",
  "테스트 분석",
  "토양",
  "현금흐름",
  "AI",
  "DEX",
  "ERP 분석",
  "GIS",
  "NFT",
];

export const keywordCardData = [
  {
    number: 1,
    duration: "3주 이내",
    title: "경공매 낙찰 예상가AI 추정",
    description:
      "경공매 낙찰 통계 데이터를 구축하여, 지역별/유형별/유찰 회차별 낙찰 예상가를 확률적으로 자동으로 계산하고 추정할 수 있습니다.",
    imgSrc: "/asset/images/AI/keyword0.svg",
    imgDiscrption:
      "경공매 낙찰, 유찰 데이터를 모두 수집하고, 학습하여 해당 경공매 유형별 낙찰 예상가의 기대값을 추정",
    keywords: ["AI", "빅데이터", "예상 가격"],
    blueKeyword: "예상 가격",
  },
  {
    number: 2,
    duration: "4주 이내",
    title: "경공매 빅데이터 구축",
    description:
      "파편화된 경공매 데이터의 통합 수집 및 물건현황, 권리분석 등 맞춤형 경공매 분석 서비스를 구축할 수 있습니다.",
    imgSrc: "/asset/images/AI/keyword1.svg",
    imgDiscrption:
      "전국 법원 경매 물건 및 자산관리공사 공매  물건을 실시간으로 수집하고, 필터 옵션별 검색 자동화",
    keywords: ["경공매", "빅데이터", "데이터 수집"],
    blueKeyword: "경공매",
  },
  {
    number: 3,
    duration: "4주 이내",
    title: "경공매 주변 시세 분석 및 AI 추정가",
    description:
      "더 이상 발품을 팔지 않아도, 손품만으로 경공매 주변 물건 시세를 객관적으로 파악하고, AI 알고리즘으로 적정가격을 추정할 수 있습니다.",
    imgSrc: "/asset/images/AI/keyword2.svg",
    imgDiscrption:
      "경공매 물건 종별 주변 시세를 모두 수집하고, 물건 특성별AI 추정가 알고리즘 개발 및 자동화 구현",
    keywords: ["AI", "빅데이터", "부동산 가격"],
    blueKeyword: "부동산 가격",
  },
  {
    number: 4,
    duration: "3주 이내",
    title: "농지연금 예상 수령액 시뮬레이션",
    description:
      "농지연금 상품 유형별 농지 추정가격에 따른 예상 연금 수령액을 예측하고, 연도별/월별 연금 계획을 자동화하여 수립할 수 있습니다.",
    imgSrc: "/asset/images/AI/keyword3.svg",
    imgDiscrption:
      "전국 농지(전/답/과수)에대한 토지 특성, 가격, 거래 데이터 등을 학습하여 추정 모델 개발 및 구현",
    keywords: ["AI", "예상 연금", "알고리즘"],
    blueKeyword: "예상 연금",
  },
  {
    number: 5,
    duration: "3주 이내",
    title: "부실채권(NPL) 수익성 AI 시뮬레이션",
    description:
      "경공매 물건 중 부실채권(NPL) 매각/ 매수 측면에서 옵션별 시나리오 수익성 분석을 통해 리스크 매니지먼트를 할 수 있습니다.",
    imgSrc: "/asset/images/AI/keyword4.svg",
    imgDiscrption:
      "부실채권 매각 기대값 및 경공매 낙찰 예상가, 부대비용을 시나리오별로 분석하여 수익성 분석",
    keywords: ["AI", "빅데이터", "부실 채권"],
    blueKeyword: "부실 채권",
  },
  {
    number: 6,
    duration: "4주 이내",
    title: "블록체인 기반 자동차 보증서 NFT 앱",
    description:
      "소유하고 있는 자동차를 NFT화 하여 자동차의 정보를 디지털화하고, 편리하게 관리 할 수 있는 블록체인 기반의 보증서 어플리케이션이 가능합니다.",
    imgSrc: "/asset/images/AI/keyword5.svg",
    imgDiscrption:
      "블록체인 기반 자동차 정보의 기록으로 자산 가치를 보호하며,  손쉽게 관리할 수 있는 모바일앱으로 구현",
    keywords: ["블록체인", " NFT", "보증서"],
    blueKeyword: "보증서",
  },
  {
    number: 7,
    duration: "4주 이내",
    title: "빅데이터 기반 검색엔진 구축",
    description:
      "수천 만개, 수억 개 이상의 빅데이터 속에서 검색 키워드 조합에 최적화된 검색 결과 도출이 가능합니다.",
    imgSrc: "/asset/images/AI/keyword6.svg",
    imgDiscrption:
      "서비스 사용자들이 플랫폼 내 정보를 효율적으로 검색하고 신속·정확하게 정보를 얻을 수 있도록 구현",
    keywords: ["검색엔진", " 빅데이터", "자연어"],
    blueKeyword: "검색엔진",
  },
  {
    number: 8,
    duration: "6주 이내",
    title: "빅데이터 기반 통합 보안관리 관제",
    description:
      "보안 로그 데이터를 실시간으로 수집, 저장, 분석 및 모니터링하여 보안 위협을 실시간으로 탐지하고, 신속하게 대응할 수 있습니다.",
    imgSrc: "/asset/images/AI/keyword7.svg",
    imgDiscrption:
      "빅데이터 기술을 이용하여 대용량 데이터를 실시간으로 분석 및 탐지하고, 유형별 대응체계 자동화 지원",
    keywords: ["보안관제", " 빅데이터", "GIS"],
    blueKeyword: "보안관제",
  },
  {
    number: 9,
    duration: "4주 이내",
    title: "빅데이터 기반 프랜차이즈 일일 결산",
    description:
      "빅데이터 기반 지역별/가맹점별 실시간 POS 데이터 수집을 통해, 매출매입 내역에 대한 일일 결산을 신속하게 수행할 수 있습니다.",
    imgSrc: "/asset/images/AI/keyword8.svg",
    imgDiscrption:
      "기존 ERP 시스템으로는 장시간 소요되던 세분화 매출 분석 기능을 일일 결산 시스템으로 자동화",
    keywords: ["보안관제", "ERP 분석", "일일 결산"],
    blueKeyword: "일일 결산",
  },
  {
    number: 10,
    duration: "3주 이내",
    title: "빌딩 건축 AI 설계및 견적 자동화",
    description:
      "지자체 건축법규에 따라 빌딩 상태별/유형별 설계 옵션(리노베이션, 리모델링, 신축) 로직을 개발하고, 자동화 할 수 있습니다.",
    imgSrc: "/asset/images/AI/keyword9.svg",
    imgDiscrption:
      "전국 빌딩에대한 토지 및 건물 특성, 빌딩 가격, 투자 수익성 분석 등을 학습하여 설계 모델 개발",
    keywords: ["AI", "건축 설계", "알고리즘"],
    blueKeyword: "건축 설계",
  },
  {
    number: 11,
    duration: "2주 이내",
    title: "온라인 부동산 매물 빅데이터 자동 수집",
    description:
      "토지/건물 빅데이터 구축으로 온라인 상의 모든 부동산 매물에 대한 자동 수집, 주소지 파악 및 개별 상세 분석이 가능합니다.",
    imgSrc: "/asset/images/AI/keyword10.svg",
    imgDiscrption:
      "온라인상의 부동산 종류별 매물정보를 모두 수집하고, 기준 정보화하여 필터 옵션별 검색 자동화",
    keywords: ["부동산", "빅데이터", "데이터 수집"],
    blueKeyword: "데이터 수집",
  },
  {
    number: 12,
    duration: "3주 이내",
    title: "의안별 국회의원 영향도 AI 네트워크 분석",
    description:
      "의안 진행과정에서 직접적인 영향력을 미칠 수 있는 의원에 대한 관계성 분석이 가능합니다.",

    imgSrc: "/asset/images/AI/keyword11.svg",
    imgDiscrption:
      "의원 프로필, 발언 유형등 빅데이터 기반 알고리즘 개발로 의원 상호간 관계성이 높은 Key Man 도출",
    keywords: ["AI", "빅데이터", "네트워크"],
    blueKeyword: "네트워크",
  },
  {
    number: 13,
    duration: "3주 이내",
    title: "의안 통과 가능성 AI 분석",
    description:
      "국회에서 논의되는 의안에 따라 향후 기업 및 기관의 의사결정 방향이 변경될 수 있습니다. 이제 의안 통과가능성 예측으로 사전 대응을 할 수 있습니다.",
    imgSrc: "/asset/images/AI/keyword12.svg",
    imgDiscrption:
      "국회의원별 의안 정보 수집, 국회의원별 통과/기각 정보 수집 및 학습을 통해 의안 통과가능성 산출",
    keywords: ["AI", "알고리즘", "국회 의안"],
    blueKeyword: "국회 의안",
  },
  {
    number: 14,
    duration: "4주 이내",
    title: "탈중앙 DEX 거래소",
    description:
      "중앙 거래소를 거치지 않고, 암호화폐를 안전하게 직접 거래할 수 있는 탈중앙 DEX 거래소를 구축할 수 있습니다.",
    imgSrc: "/asset/images/AI/keyword13.svg",
    imgDiscrption:
      "거래소 유저 확보를 위한 충분한 유동성 풀과 높은 접근성을 갖춘 안정적 시스템 구축",
    keywords: ["암호화폐", "탈중앙거래소", "DEX"],
    blueKeyword: "탈중앙거래소",
  },
  {
    number: 15,
    duration: "4주 이내",
    title: "토양 기후별 최적 작물  AI 추천 알고리즘",
    description:
      "더 이상 경험에 의존하지 않고, 토양 및 기후 빅데이터 기반 지역별 적합작물 분석을 통해 사용자 농지에 최적화된 작물 재배 리스트를 추천할 수 있습니다.",
    imgSrc: "/asset/images/AI/keyword14.svg",
    imgDiscrption:
      "전국 주소지별 농지 정보, 토양 정보및 기후 정보 수집 및 학습을 통해 재배가능한 작물 추천모델 개발",
    keywords: ["AI", "작물 추천", "토양"],
    blueKeyword: "작물 추천",
  },
  {
    number: 16,
    duration: "3주 이내",
    title: "현금흐름 분석 기반 대출 가능성 AI 진단",
    description:
      "신용평가 정보 외에 매출 및 비용 구조에 대한 맞춤형 분석으로 현금흐름을 파악하고, 대출 가능성을 진단할 수 있습니다.",
    imgSrc: "/asset/images/AI/keyword15.svg",
    imgDiscrption:
      "업종 특성별 매출 및 비용구조를 데이터를 표준화하고, 현금흐름에 입각한 대출 가능성 알고리즘 개발",
    keywords: ["현금흐름", "AI", "대출 가능성"],
    blueKeyword: "대출 가능성",
  },
  {
    number: 17,
    duration: "2주 이내",
    title: "AI 농지(토지 포함) 가격 추정 알고리즘",
    description:
      "매매 사례가 많지 않아 가격을 알 수 없었던  농지! 이제 주소지 입력 만으로 실시간으로 가격 추정을 할 수 있습니다.",
    imgSrc: "/asset/images/AI/keyword16.svg",
    imgDiscrption:
      "전국 농지(전/답/과수)에대한 토지 특성, 가격, 거래 데이터 등을 학습하여 추정 모델 개발 및 구현",
    keywords: ["AI", "빅데이터", "부동산 가격"],
    blueKeyword: "부동산 가격",
  },
  {
    number: 18,
    duration: "2주 이내",
    title: "AI 단독주택및 빌라 가격 추정 알고리즘",
    description:
      "아파트와는 달리 거래 사례가 적은 주택과 빌라! 그러다보니 사기 거래가 양산될 수 있습니다. 이제 빅데이터/AI로 적정 가격을 추정할 수 있습니다.",
    imgSrc: "/asset/images/AI/keyword17.svg",
    imgDiscrption:
      "전국 단독주택, 빌라에대한 건물/토지 특성, 가격, 거래 데이터 등을 학습하여 추정 모델 개발 및 구현",
    keywords: ["AI", "빅데이터", "부동산 가격"],
    blueKeyword: "부동산 가격",
  },
  {
    number: 19,
    duration: "10주 이내",
    title: "AI 보이스피싱 탐지 및 차단",
    description:
      "통화내역을 실시간으로 분석하여 보이스피싱 여부를 실시간으로 AI가 탐지하고, 통화 차단이 가능합니다.",
    imgSrc: "/asset/images/AI/keyword18.svg",
    imgDiscrption:
      "AI 딥러닝 기술 등을 이용하여 보이스피싱 위험도를 자동으로 분류하고, 탐지/경고/차단 지원",
    keywords: ["AI", "보이스피싱", "딥러닝"],
    blueKeyword: "보이스피싱",
  },
  {
    number: 20,
    duration: "2주 이내",
    title: "AI 빌딩 가격 추정 알고리즘",
    description:
      "빌딩은 파는 사람도, 사는 사람도 적정한 매매 가격을 알 수가 없었습니다. 이제 빌딩 주소지 입력 만으로 적정 가격을 AI로 추정할 수 있습니다.",
    imgSrc: "/asset/images/AI/keyword19.svg",
    imgDiscrption:
      "전국 빌딩에대한 건물/토지 특성, 가격, 거래 데이터 등을 학습하여 추정 모델 개발 및 구현",
    keywords: ["AI", "빅데이터", "부동산 가격"],
    blueKeyword: "부동산 가격",
  },
  {
    number: 21,
    duration: "4주 이내",
    title: "NFT 마켓 플레이스",
    description:
      "Opensea, DeFineArt와 같은 예술품 및 컨텐츠 거래소 플랫폼을 이더리움, 바이낸스, 클레이튼 체인 기반으로 통합된 NFT 거래 마켓으로 구축합니다.",
    imgSrc: "/asset/images/AI/keyword20.svg",
    imgDiscrption:
      "고객의 니즈에 따라 중앙화 거래소를 비롯한 탈중앙 DEX 거래소를 구축하여 NFT 거래 플랫폼 구현",
    keywords: ["NFT", "블록체인", "거래소"],
    blueKeyword: "NFT",
  },
  {
    number: 22,
    duration: "3주 이내",
    title: "경영평가 지적 사항 유형별 AI 자동 분류",
    description:
      "수만개의 경영정보! 유형별 카테고리화 되지 않고선 체계화하고 모니터링 할 수 없습니다. 이제 AI로 자동 분류하고 활용 기반을 만들 수 있습니다.",
    imgSrc: "/asset/images/AI/keyword21.svg",
    imgDiscrption: `공공기관/공기업의 경영평가 결과를 모두 수집하고,\n 지적사항 유형을 AI 로 자동으로 분류/카테고리화`,
    keywords: ["AI", "자동 분류", "데이터 학습"],
    blueKeyword: "자동 분류",
  },
  {
    number: 23,
    duration: "2주 이내",
    title: "국회의원 발언별 AI 긍정/부정 성향 분석",
    description:
      "국회의원의 발언 정보를 바탕으로 해당 발언의 성향(긍정/부정/중립)을 AI로 자동 판단 및 검토/활용할 수 있습니다.",
    imgSrc: "/asset/images/AI/keyword22.svg",
    imgDiscrption:
      "국회의원 프로필, 국회의원 의안별 발언 데이터를\n모두 수집하고, 생성형 AI로 긍정/부정 성향도 파악",
    keywords: ["AI", "긍정/부정", "테스트 분석"],
    blueKeyword: "긍정/부정",
  },
  {
    number: 24,
    duration: "8주 이내",
    title: "국회 의안/법령/규제 빅데이터 분석 시스템",
    description:
      "파편화된 정책 정보와 개별 단위 검색으로\n 원스톱으로 통합 정보 제공이 어려웠습니다.\n 이제 한 곳에서 한 번에 검색/분석이 가능합니다.",
    imgSrc: "/asset/images/AI/keyword23.svg",
    imgDiscrption:
      "의안/법령/규제/정책 등 정책 관련 통합 빅데이터\n 시스템을 구축하고, 검색엔진 기반 최적 검색 제공",
    keywords: ["빅데이터", "기준정보", "검색엔진"],
    blueKeyword: "검색엔진",
  },
  {
    number: 25,
    duration: "4주 이내",
    title: "농촌 숨은 정책지원금 수집 자동화 및 검색",
    description:
      "대한민국에 지자체별로 산재한 정책지원금 정보!\n 수천 개의 정보 속에서 사용자에게 맞는 최적화된 \n검색결과를 맞춤형으로 제공 가능합니다.",
    imgSrc: "/asset/images/AI/keyword24.svg",
    imgDiscrption:
      "지자체별 농촌/농업 관련 교육 및 지원사업에 대한\n 정보를 모두 수집하고, 필터 옵션별 검색 자동화",
    keywords: ["빅데이터", "검색 분류", "검색엔진"],
    blueKeyword: "빅데이터",
  },
  {
    number: 26,
    duration: "4주 이내",
    title: "지자체 법규 기반 AI 주택 설계 및 견적",
    description:
      "인테리어 사기거래. 이제 알고 대응할 수 있습니다. \n사용자의 주택 특성에 맞는 설계 및 견적을 바탕으로 업체를 추천하는 서비스 제공도 가능합니다",
    imgSrc: "/asset/images/AI/keyword25.svg",
    imgDiscrption:
      "지자체별 건축 법규 데이터를 모두 수집하고,\n 주소지에 적합한 주택 설계 유형 추천 및 견적 자동화",
    keywords: ["AI", "건축설계", "견적"],
    blueKeyword: "건축설계",
  },
  {
    number: 27,
    duration: "3주 이내",
    title: "지자체별 규제 커버리지 빅데이터 분석",
    description:
      "대한민국에 규제는 수도 없이 많습니다. 그리고 지자체별로 규제 시행 여부는 제각각입니다. 해당 규제가 없는 곳과 있는 곳을 한눈에 비교분석 가능합니다.",
    imgSrc: "/asset/images/AI/keyword26.svg",
    imgDiscrption:
      "전국 시도, 시군구 단위 지자체별 규제 현황을 모두 \n수집하고, 해당 규제 실시 여부와 커버리지율 파악 ",
    keywords: ["빅데이터", "정책", "규제"],
    blueKeyword: "규제",
  },
];
