const Section04 = () => {
  return (
    <div className="ai-section-wrap-bg-white">
      <div className="ai-section-inner-p112 ">
        <div className="section-title-box">
          <div className="section-title-text">Hecto Labs AI Solution</div>
          <div className="section-description-text">AI 솔루션</div>
        </div>
        <div className="section-content-box">
          <div className="solution-design">
            <div className="ai-solution-card">
              <img src="/asset/images/AI/ai-solution01.svg" alt="" />
              <div className="ai-solution-text-box">
                <span className="ai-solution-text1">데이터 분석 솔루션</span>
                <span className="ai-solution-text2">
                  회귀 분석, 군집 분석, 분류 분석, 시계열 예측 분석, 이상탐지
                  분석 가능! R, Python 필요없이 최신 딥러닝 모델로 데이터 분석을
                  할 수 있습니다.
                </span>
              </div>
            </div>
            <div className="ai-solution-card">
              <img src="/asset/images/AI/ai-solution02.svg" alt="" />
              <div className="ai-solution-text-box">
                <span className="ai-solution-text1">이미지 딥러닝 솔루션</span>
                <span className="ai-solution-text2">
                  헥토랩스 솔루션을 통해 이미지 분류, 객체 탐지, 객체 분할, 생성
                  모델, 오픈포즈 학습을 코딩없이 실행하고 최적의 AI 모델을
                  개발할 수 있습니다.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Section04;
