import { useState } from "react";
import "./Main.css";
import Topnav from "../../components/topnav/Topnav";
import Section01 from "./section/section01/Section01";
import Section02 from "./section/section02/Section02";
import Section03 from "./section/section03/Section03";
import Section04 from "./section/section04/Section04";
import Section05 from "./section/section05/Section05";
import Section06 from "./section/section06/Section06";
import Footer from "../../components/footer/Footer";
import FloatingBtnBox from "../../components/floatingBtnBox/FloatingBtnBox";
import InquiryPopUp from "../../components/InquiryPopUp/InquiryPopUp";
import InquiryBtn from "../../components/inquiryBtn/InquiryBtn";
import Update from "./section/update/Update";

export const Main = () => {
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleScrollPast = (scrolled) => {
    setScrolled(scrolled);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        position: "relative",
      }}
    >
      <Topnav setOpen={setOpen} />
      <Section01 setOpen={setOpen} onScrollPast={handleScrollPast} />
      <Update />
      <Section02 />
      <Section03 />
      <Section04 />
      <Section05 />
      <Section06 setOpen={setOpen} />
      <Footer />
      <FloatingBtnBox setOpen={setOpen} />
      {open && <InquiryPopUp setOpen={setOpen} />}
      {scrolled && (
        <InquiryBtn
          setOpen={setOpen}
          bottom={56}
          position="fixed"
          boxShadow={true}
        />
      )}
    </div>
  );
};

export default Main;
