declare global {
  interface Window {
    fbq: any;
  }
}

const InquiryBtn = ({
  setOpen,
  bottom,
  position,
  boxShadow,
}: {
  setOpen: any;
  bottom: number;
  position?: any;
  boxShadow?: boolean;
}) => {
  const handleClick = () => {
    if (window.fbq) {
      window.fbq("track", "Contact");
    }

    setOpen(true);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "68px",
        borderRadius: "99999px",
        backgroundColor: "#00B0F0",
        padding: "16px 28px",
        cursor: "pointer",
        position: position,
        left: "50%",
        transform: "translateX(-50%)",
        bottom: bottom + "px",
        boxShadow: boxShadow ? "4px 4px 12px 0px rgba(0, 0, 0, 0.25)" : "none",
      }}
      onClick={handleClick}
    >
      <span
        style={{
          fontFamily: "Spoqa Han Sans Neo",
          fontWeight: "700",
          fontSize: "30px",
          color: "#fff",
        }}
      >
        AI개발 상담신청
      </span>
      <img src="/asset/images/arrow/Arrow-Right-white.svg" alt="" />
    </div>
  );
};
export default InquiryBtn;
