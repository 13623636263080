import { useState } from "react";
import Footer from "../../components/footer/Footer";
import Topnav from "../../components/topnav/Topnav";
import "./Team.css";
import FloatingBtnBox from "../../components/floatingBtnBox/FloatingBtnBox";
import InquiryPopUp from "../../components/InquiryPopUp/InquiryPopUp";
import InquiryBtn from "../../components/inquiryBtn/InquiryBtn";

const profileList = [
  {
    name: "김기현 CEO",
    education: "성균관대 한양대 박사(Ph.D.)",
    list: [
      "DAMA(국제 데이터매니지먼트협회) 한국 대표",
      "한양대학교 스마트시티공학과 겸임교수",
      "한림국제대학원대학교 교수(데이터 매니지먼트)",
      "삼정KPMG 전략컨설팅본부 이사",
      "삼성서울병원 정보전략실 책임",
      "삼성 오픈타이드 시니어매니저",
    ],
  },
  {
    name: "신연성 파트너",
    education: "포항공대",
    list: [
      "행정안전부 주소미래혁신포럼 위원",
      "㈜앤서 CTO",
      "DAMA Korea 이사",
      "㈜크래프트이더 연구소장",
      "이더리움기업연합 한국지사 연구소장",
    ],
  },
  {
    name: "조윤경 파트너",
    education: "북경대",
    list: [
      "㈜녹현리서치 전략기획실장",
      "DAMA Korea 이사",
      "TV조선 사회부 기자",
      "뉴스토마토 경제부 기자",
      "KB증권 해외선물(농산물 담당)",
      "CALYON Bank IB본부",
    ],
  },
  {
    name: "이봉석 파트너",
    education: "서울대, 도쿄대 박사(Ph.D)",
    list: [
      "노무라종합연구소 팀장",
      "삼정KPMG 부동산본부 이사",
      "삼성물산 건설부문 차장",
      "Inpact G.C.F 컨설턴트",
      "일본 토지종합연구소 조사부 연구원",
    ],
  },
  {
    name: "박성필 파트너",
    education: "성균관대 석사",
    list: [
      "트랜스파머㈜ 개발이사",
      "㈜앤서 개발이사",
      "㈜디에스앤텍 연구소 차장",
    ],
  },
  {
    name: "김일숙 파트너",
    education: "한국외대, 중앙대 석사",
    list: [
      "대통령비서실 디지털소통 행정관(3급)",
      "JTBC 디지털콘텐트 팀장(부장)",
      "SBS 미디어서비스 담당(부장)",
      "CBSi 노컷뉴스 런칭 팀장(차장)",
    ],
  },
];

const Team = () => {
  const [open, setOpen] = useState(false);
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        position: "relative",
      }}
    >
      <Topnav setOpen={setOpen} />
      <div className="team-section-wrap" style={{ height: "474px" }}>
        <div className="team-section01-inner">
          <div className="team-section01-title">Team</div>
          <div className="team-section01-text">
            KPMG, 노무라연구소 등 글로벌컨설팅펌 출신 기획 전문가들과 <br />
            서울대/북경대/동경대/포항공대 등 AI와 빅데이터, 블록체인 전문가가
            함께합니다. <br /> 나만의 ‘솔루션 디자인’이 필요하다면, 최고의 팀이
            만들어 드립니다.
          </div>
        </div>
      </div>
      <div className="team-section-wrap-bg-white">
        <div className="ai-section-inner-p112 ">
          <div className="section-title-box">
            <div className="section-title-text">We Are</div>
            <div className="section-description-text">
              Hecto + Labs
              <span className="gray-text">
                {" "}
                (100배 + 도전) <br />
              </span>
              100배의 혁신을 위한 실험과 도전을 계속합니다.
            </div>
          </div>
          <div className="section-content-box">
            <div className="weare-design">
              {profileList.map((profile, index) => (
                <div className="weare-card" key={index}>
                  <div className="weare-card-text-box">
                    <div className="weare-card-text-top">
                      <div className="profile-name">{profile.name}</div>
                      <div className="profile-education">
                        {profile.education}
                      </div>
                    </div>
                    <div className="weare-card-text-list">
                      {profile.list.map((list, index) => (
                        <div className="weare-card-list-item" key={index}>
                          <img src="/asset/images/Main/list-dot.svg" alt="" />
                          <span>{list}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <img
                    src={`/asset/images/Team/profile${index + 1}.png`}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <InquiryBtn
        setOpen={setOpen}
        bottom={56}
        position="fixed"
        boxShadow={true}
      />
      <FloatingBtnBox setOpen={setOpen} />
      {open && <InquiryPopUp setOpen={setOpen} />}
    </div>
  );
};
export default Team;
