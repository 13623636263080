import "./AiSection.css";

const Section02 = () => {
  return (
    <div className="ai-section-wrap-bg-white">
      <div className="ai-section-inner-p112 ">
        <div className="section-title-box">
          <div className="section-title-text">
            Hacto Labs Solution Optimizer
          </div>
          <div className="section-description-text">
            문제 해결 최적화 솔루션
          </div>
        </div>
        <div className="section-content-box">
          <div className="solution-design">
            <img src="/asset/images/AI/s1-solution.svg" alt="" />
            <span className="hidden-text">
              AI 가능성,대출 가능성 진단,의안 통과 가능성, 의안별 국회의원
              영향도,AI 탐지/추천,보이스피싱 탐지/차단,농지 최적 작물 추천,발언
              AI 긍정/부정 성향 분석,AI 가격,부동산 가격 추정,경공매 낙찰
              예상가,경공매 물건 시세 추정가,AI 재무,프랜차이즈 일일
              결산,부실채권(NPL) 수익성 분석,연금 예상 수령액 시뮬레이션,AI
              설계/견적,주택 설계 및 견적,빌딩 건축 설계및 견적,스마트팜 설계 및
              견적,빅데이터/검색엔진,검색엔진 구축,빅데이터 통합 구축,통합
              보안관리 관제,블록체인/NFT,NFT 마켓 플레이스,탈중앙 DEX
              거래소,자동차 보증서 NFT 앱
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Section02;
