import { useState } from "react";
import Footer from "../../components/footer/Footer";
import Topnav from "../../components/topnav/Topnav";
import Section01 from "./section/Section01";
import Section02 from "./section/Section02";
import Section03 from "./section/Section03";
import Section04 from "./section/Section04";
import Section05 from "./section/Section05";
import FloatingBtnBox from "../../components/floatingBtnBox/FloatingBtnBox";
import InquiryPopUp from "../../components/InquiryPopUp/InquiryPopUp";
import InquiryBtn from "../../components/inquiryBtn/InquiryBtn";

const AiMarket = () => {
  const [open, setOpen] = useState(false);
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        position: "relative",
      }}
    >
      <Topnav setOpen={setOpen} />
      <Section01 />
      <Section02 />
      <Section03 />
      <Section04 />
      <Section05 />
      <Footer />
      <InquiryBtn
        setOpen={setOpen}
        bottom={56}
        position="fixed"
        boxShadow={true}
      />
      <FloatingBtnBox setOpen={setOpen} />
      {open && <InquiryPopUp setOpen={setOpen} />}
    </div>
  );
};
export default AiMarket;
