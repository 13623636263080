import "./Section04.css";

const Section04 = () => {
  return (
    <div className="section04-wrap">
      <div className="section-4">
        <div className="section04-title">
          <div className="performance">Performance</div>
          <div className="div">
            여러 고객사가 이미
            <br />
            유의미한 성과를 창출하고 있습니다.
          </div>
        </div>
        <div className="contents">
          <div className="s4-card">
            <div className="frame-80">
              <div className="frame-2609921">
                <div className="sub">
                  <div className="s4-paragraph">최대 정확도 95%</div>
                  <div className="s4-paragraph2">AI 가격 추정</div>
                </div>
                <img src="/asset/images/Main/location.svg" alt="" />
              </div>
              <div className="s4-paragraph3">
                가격 분석 AI로 3,800만여 개 토지를 모두 분석하여 AI 가격 추정
                서비스 런칭
              </div>
            </div>
            <img src="/asset/images/Main/transfarmer.svg" alt="" />
          </div>
          <div className="s4-card">
            <div className="frame-80">
              <div className="frame-2609921">
                <div className="sub">
                  <div className="s4-paragraph">최대 정확도 100%</div>
                  <div className="s4-paragraph2">AI 빌딩 건축설계</div>
                </div>
                <img src="/asset/images/Main/building.svg" alt="" />
              </div>
              <div className="s4-paragraph3">
                기존 빌딩 설계 업무를 리노베이션, 리모델링, 신축 유형별 설계
                AI로 자동화
              </div>
            </div>
            <img src="/asset/images/Main/buildinglogo.svg" alt="" />
          </div>
          <div className="s4-card">
            <div className="frame-81">
              <div className="frame-2609921">
                <div className="sub">
                  <div className="s4-paragraph">최소 효율화 50%</div>
                  <div className="s4-paragraph2">AI 부실채권 분석</div>
                </div>
                <img src="/asset/images/Main/document.svg" alt="" />
              </div>
              <div className="s4-paragraph3">
                경공매 자료 수집 자동화와 AI 부실채권 수익성 분석으로 기존
                수작업 대비 50% 업무 효율화
              </div>
            </div>
            <div className="frame-2609952">
              <img src="/asset/images/Main/nscanner.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Section04;
