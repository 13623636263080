import "./AiSection.css";
const Section05 = () => {
  return (
    <div className="ai-section-wrap" style={{ height: "468px" }}>
      <div className="ai-section-inner-p112">
        <div className="ai-market-performance">
          <div className="section-title-box">
            <div className="section-title-text" style={{ color: "#fff" }}>
              AI Market Performance
            </div>
            <div className="section-description-text" style={{ color: "#fff" }}>
              아는 만큼 보입니다. <br />
              Zero Based에서 출발하지 않고, <br /> Value Added 합니다.
            </div>
          </div>
          <div className="performance-content-box">
            <img src="/asset/images/AI/perfomance01.svg" alt="" />
            <img src="/asset/images/AI/perfomance02.svg" alt="" />
            <img src="/asset/images/AI/perfomance03.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section05;
