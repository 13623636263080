import "./Section03.css";

const Section03 = () => {
  return (
    <div className="section03-wrap">
      <div className="section-2">
        <div className="title">
          <div className="strength">Strength</div>
          <div className="div">
            기획에서 개발까지
            <br />
            고객과 함께합니다.
          </div>
        </div>
        <div className="frame-92">
          <div className="frame-90">
            <div className="paragraph">01</div>
            <div className="frame-91">
              <div className="paragraph2">맞춤형 AI 솔루션 디자인 및 개발</div>
              <div className="frame-93">
                <div className="paragraph3">
                  개발 성과 달성을 위한 목표 기반 컨설팅 및 지원
                </div>
                <div className="paragraph3">
                  개발 후에도 고객이 지속적으로 검증하고 추가 활용할 수 있도록
                  A/S 지원
                </div>
              </div>
            </div>
          </div>
          <div className="frame-912">
            <div className="paragraph">02</div>
            <div className="frame-91">
              <div className="paragraph2">
                기획에서 개발까지 전 과정 통합 개발
              </div>
              <div className="frame-94">
                <div className="paragraph3">
                  AI와 데이터 적용을 ‘기획~개발’ One-Stop 개발
                </div>
                <div className="paragraph3">
                  글로벌 컨설팅펌 출신 경영 컨설턴트와 AI/빅데이터 전문가들이
                  정확한 판단 제시
                </div>
              </div>
            </div>
          </div>
          <div className="frame-922">
            <div className="paragraph">03</div>
            <div className="frame-91">
              <div className="paragraph2">다양한 종류의 데이터에 대응</div>
              <div className="frame-94">
                <div className="paragraph3">
                  수십 억개 이상의 데이터는 물론, 센서/영상 데이터 등 비구조
                  데이터에도 대응 가능
                </div>
                <div className="paragraph3">
                  용도에 따른 딥러닝 및 머신러닝, 최적화 및 강화학습 등 적절한
                  알고리즘 선택 컨설팅
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Section03;
