import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-inner">
        <div className="footer-title">
          <img src="/asset/images/logo/footer-logo.svg" alt="" />
        </div>
        <div className="footer-info">
          <div className="text-row">
            <div className="row-item-text">사업자등록번호 : 507-87-02631</div>
            <div className="divider-line"></div>
            <div className="row-item-text">
              통신판매업신고번호 : 2024-서울서초-0689
            </div>
          </div>
          <div className="text-row">
            <div className="row-item-text">
              서울특별시 서초구 매헌로 24 NH농협 디지털혁신캠퍼스 5층
              트랜스파머(주) 헥토랩스 사업부
            </div>
          </div>
          <div className="text-row">
            <div className="row-item-text">
              고객센터 : 02)555-2822 (평일 09:00~18:00)
            </div>
            <div className="divider-line"></div>
            <div className="row-item-text">Tel : 02-555-2822</div>
            <div className="divider-line"></div>
            <div className="row-item-text">E-mail : biz@transfarmer.co.kr</div>
          </div>
          <div className="text-row">
            <div className="row-item-text">
              COPYRIGHT ⓒ 2024 TRANSFARMER ALL RIGHTS RESERVED.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
