import "./Section05.css";

const Section05 = () => {
  return (
    <div className="section05-wrap">
      <div className="section-5">
        <div className="s5-contents">
          <div className="s5-title">
            <div className="process">Process</div>
            <div className="s5-title-text">
              단순 개발 방식에서 벗어나
              <br />
              문제 해결 중심의 ‘솔루션 디자인’을 지원합니다.
            </div>
          </div>
          <div className="slider-container">
            <div className="slider">
              {cardData.map((card, index) => (
                <div className="slide-card" key={index}>
                  <div
                    className="card-top"
                    style={{ backgroundColor: card.backgroundColor }}
                  >
                    <div className="card-top-inner">
                      <div className="card-top-text">
                        <span className="number">{card.number}</span>
                        <span className="card-top-title">{card.title}</span>
                      </div>
                      <span className="tag-box">{card.tag}</span>
                    </div>
                    <img src={card.imgSrc} alt={card.title} />
                  </div>
                  <div className="card-bottom">
                    {card.bottomTexts.map((text, idx) => (
                      <div className="text-list" key={idx}>
                        <img
                          src="/asset/images/Main/list-dot.svg"
                          alt=""
                          className="list-dot"
                        />
                        <div className="card-bottom-text">
                          <span className="card-bottom-title">{text}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section05;

const cardData = [
  {
    number: "01",
    title: "방향성 정립",
    backgroundColor: "#0060d1",
    tag: "검증 단계",
    imgSrc: "/asset/images/Main/compass.svg",
    bottomTexts: [
      "비즈니스 환경 분석 및 AI도입 여부 검토",
      "목적 및 스케줄 명확화",
    ],
  },
  {
    number: "02",
    title: "솔루션 디자인",
    backgroundColor: "#00ABD1",
    tag: "검증 단계",
    imgSrc: "/asset/images/Main/hand.svg",
    bottomTexts: [
      "알고리즘 입출력 설계",
      "데이터 유무 검토",
      "업무 활용방안 검토",
    ],
  },

  {
    number: "03",
    title: "모델 학습·검증",
    backgroundColor: "#144483",
    tag: "검증 단계",
    imgSrc: "/asset/images/Main/check.svg",
    bottomTexts: [
      "데이터 수집 및 머신러닝 모델 학습",
      "비즈니스 관점에서의 정밀도 검증",
    ],
  },

  {
    number: "04",
    title: "개발·테스트",
    backgroundColor: "#4368B8",
    tag: "개발 및 운영 단계",
    imgSrc: "/asset/images/Main/develop.svg",
    bottomTexts: [
      "시스템 개발 및 기존 시스템과 연동",
      "비즈니스 프로세스와의 결합",
    ],
  },

  {
    number: "05",
    title: "운영",
    backgroundColor: "#6188B5",
    tag: "개발 및 운영 단계",
    imgSrc: "/asset/images/Main/setting.svg",
    bottomTexts: [
      "업무에서의 실제 가동",
      "데이터 수집에 따른 재학습 등 정밀도 향상",
    ],
  },
];
