import "./AiSection.css";

const Section01 = () => {
  return (
    <div className="ai-section-wrap">
      <div className="ai-section01-inner">
        <div className="ai-section01-title">AI Market</div>
        <div className="ai-section01-text">
          Hecto Labs의 다양한 프로젝트를 경험해 보세요. <br /> 나만의 AI가
          필요하다면, 맞춤형 ‘솔루션 디자인’ 서비스를 이용해 보세요.
        </div>
      </div>
    </div>
  );
};
export default Section01;
