import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./page/main/Main";
import HowWeDo from "./page/howwedo/Howwedo";
import AiMarket from "./page/aimarket/AiMarket";
import Team from "./page/team/Team";

function App() {
  return (
    <Router>
      <div className="App" style={{ width: "100%", height: "100%" }}>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/howwedo" element={<HowWeDo />} />
          <Route path="/aimarket" element={<AiMarket />} />
          <Route path="/team" element={<Team />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
