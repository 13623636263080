import "./Section.css";

const Section01 = () => {
  return (
    <div className="how-section-wrap">
      <div className="how-section01-inner">
        <div className="how-section01-title">How We Do</div>
        <div className="how-section01-text">
          AI / Big Data 등 솔루션을 개발하는 회사들은 많습니다. <br /> 하지만
          Hecto Labs와 같이, 비즈니스 성과를 내기 위해 <br />
          비즈니스 관점에서 ‘솔루션 디자인’을 하는 기업은 없습니다.
        </div>
      </div>
    </div>
  );
};
export default Section01;
