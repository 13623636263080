import { useState } from "react";
import "./InquiryPopUp.css";
import BoardService from "../../servie/Inquiry";

const InquiryPopUp = ({ setOpen }: { setOpen: any }) => {
  const [type, setType] = useState("L");
  const [purpose, setPurpose] = useState("N");
  const [affiliation, setAffiliation] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [content, setContent] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [loading, setLoading] = useState(false);

  const Submit = async () => {
    setLoading(true);
    try {
      let formData = new FormData();

      formData.append("type", type);
      formData.append(
        "title",
        affiliation === "기업/기관"
          ? affiliation + " / " + companyName
          : affiliation,
      );
      formData.append("purpose", purpose);
      formData.append("content", content);
      formData.append("email", email);
      formData.append("phone", phone);
      await BoardService.WriteInuqiry(formData);
      setLoading(false);
      alert("문의가 접수되었습니다. 빠른 시일 내에 답변드리겠습니다.");
      setOpen(false);
    } catch (error) {
      setLoading(false);
      console.error("Submit 함수에서 에러 발생:", error);
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Check if the input contains non-numeric characters
    const isNumeric = /^[0-9]*$/.test(value);

    if (isNumeric) {
      setPhone(value);
      setPhoneError(false);
    } else {
      setPhoneError(true);
    }
  };

  const isFormComplete =
    affiliation &&
    email &&
    phone &&
    content &&
    (affiliation !== "company" || companyName) &&
    !phoneError &&
    email.includes("@");

  return (
    <div className="inquiry-container">
      <div className="inquiry-popup">
        <div className="inquiry-popup-title-box">
          <div className="inquiry-popup-title">문의하기</div>
          <img
            src="/asset/images/icon/close.svg"
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => setOpen(false)}
          />
        </div>
        <div className="inquiry-popup-content-box">
          <div className="inquiry-popup-content-title">
            쉽고, 빠르고, 합리적으로 <br /> 솔루션을 개발하고 싶으신가요?
          </div>
          <div className="inquiry-input-box">
            <div className="inquiry-input-item">
              <div className="inquiry-input-title">소속 선택</div>
              <div className="inquiry-select">
                <span
                  className={
                    affiliation === "기업/기관"
                      ? "inquiry-select-option-selected"
                      : "inquiry-select-option"
                  }
                  onClick={() => setAffiliation("기업/기관")}
                >
                  기업/기관
                </span>
                <span
                  className={
                    affiliation === "예비창업자"
                      ? "inquiry-select-option-selected"
                      : "inquiry-select-option"
                  }
                  onClick={() => setAffiliation("예비창업자")}
                >
                  예비창업자
                </span>
                <span
                  className={
                    affiliation === "개인"
                      ? "inquiry-select-option-selected"
                      : "inquiry-select-option"
                  }
                  onClick={() => setAffiliation("개인")}
                >
                  개인
                </span>
              </div>
              {affiliation === "기업/기관" && (
                <input
                  type="text"
                  className="inquiry-input"
                  placeholder="기업 혹은 기관명"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  style={{ marginTop: "8px" }}
                />
              )}
            </div>
            <div className="inquiry-input-item">
              <div className="inquiry-input-title">이메일</div>
              <div>
                <input
                  type="text"
                  className="inquiry-input"
                  placeholder="회신 받을 이메일을 입력해주세요."
                  onChange={(e) => setEmail(e.target.value)}
                />
                {email.length > 0 && !email.includes("@") && (
                  <div className="inquiry-input-error">
                    이메일 형식이 올바르지 않습니다.
                  </div>
                )}
              </div>
            </div>
            <div className="inquiry-input-item">
              <div className="inquiry-input-title">연락처</div>
              <div>
                <input
                  type="phone"
                  maxLength={11}
                  className="inquiry-input"
                  placeholder="‘-’를 제외한 휴대폰 번호를 작성해주세요."
                  value={phone}
                  onChange={handlePhoneChange}
                />
                {phoneError && (
                  <div className="inquiry-input-error">
                    숫자만 입력해 주세요.
                  </div>
                )}
              </div>
            </div>
            <div className="inquiry-input-item">
              <div className="inquiry-input-title">
                <span>문의내용</span>
                <div>
                  <span style={{ fontSize: 14 }}> {content.length}</span>
                  <span className="content-maxlength"> / 2000</span>
                </div>
              </div>
              <textarea
                name=""
                id=""
                maxLength={2000}
                className="inquiry-textarea"
                placeholder="문의사항을 자세히 알려주세요"
                onChange={(e) => setContent(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="submit-btn-container">
          <button
            className="submit-btn"
            onClick={Submit}
            style={{
              backgroundColor: isFormComplete
                ? "rgba(23, 30, 38, 0.78)"
                : "#f0f0f0",
              color: isFormComplete ? "white" : "rgba(23, 30, 38, 0.4)",
            }}
            disabled={!isFormComplete}
          >
            {loading ? (
              <img
                src="/asset/images/icon/loop.gif"
                alt=""
                style={{ width: "36px", height: "36px" }}
              />
            ) : (
              <span>접수</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InquiryPopUp;
