import "./Section.css";

const cardData = [
  {
    text1: "성과 창출을 위한 AI",
    text2: (
      <>
        고객의 문제 해결에 가장 적합한 AI 개발을 위해 <br />
        ‘목적, 전략, 설계, 계획’ 등 필요한 모든 것을 고려하여 <br />
        One-Stop으로 개발합니다.
      </>
    ),
    imgSrc: "/asset/images/How/step.svg",
  },
  {
    text1: "숨겨진 기회의 발견",
    text2: (
      <>
        ‘AI의 가능성’과 ‘비즈니스에서 의미’의 접점 도출을 통해서 <br />
        AI 솔루션의 활용 가능성을 도출하고, 설계에 반영합니다.
      </>
    ),
    imgSrc: "/asset/images/How/bulb.svg",
  },
  {
    text1: "사용 방안에 대한 깊은 고민",
    text2: (
      <>
        ‘비즈니스로 의미 있는 AI’를 위해 <br />
        실제 현장에서 어떻게 사용될지, 어느 정도 정밀도가 필요한지 등<br />
        개발 전 단계부터 구상합니다.
      </>
    ),
    imgSrc: "/asset/images/How/document.svg",
  },
  {
    text1: "고객과의 동행",
    text2: (
      <>
        ‘비즈니스의 내용을 가장 잘 이해하는 고객’과 <br />
        Hecto Labs의 AI 창의적 기술력을 연결하여 가치 있는 AI를 만듭니다.
      </>
    ),
    imgSrc: "/asset/images/How/hands.svg",
  },
];
const Section02 = () => {
  return (
    <div className="how-section-wrap-bg-white">
      <div className="how-section02-inner">
        <div className="section-title-box">
          <div className="section-title-text">Solution Design</div>
          <div className="section-description-text">
            무엇을 상상하든 그 이상을 만들어 드립니다. <br />
            100배의 혁신을 위한 실험과 도전은 계속됩니다.
          </div>
        </div>
        <div className="section-content-box">
          <div className="solution-design">
            {cardData.map((card, index) => (
              <div className="solution-design-card-box" key={index}>
                <div className="solution-design-card-texts">
                  <div className="solution-design-card-text1">{card.text1}</div>
                  <div className="solution-design-card-text2">{card.text2}</div>
                </div>
                <div className="solution-design-img-box">
                  <img src={card.imgSrc} alt="" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Section02;
