import "./Section06.css";

const Section06 = ({ setOpen }: { setOpen: any }) => {
  return (
    <div className="section06-wrap">
      <div className="section-6">
        <div className="s6-left">
          <div className="s6-text-box">
            <span className="s6-title">We Are</span>
            <span className="s6-text">
              Hecto Labs는 <br /> 100배의 혁신을 위한 <br />
              실험과 도전을 의미합니다.
            </span>
          </div>
          <button className="inquiry-btn" onClick={() => setOpen(true)}>
            <span className="inquiry-btn-text">문의 바로하기</span>
            <img src="/asset/images/icon/plus.svg" alt="" />
          </button>
        </div>
        <img src="/asset/images/Main/3d-100.svg" alt="" />
      </div>
    </div>
  );
};

export default Section06;
