import "./Section.css";
const Section041 = () => {
  return (
    <div className="how-section-wrap-bg-white">
      <div className="how-section02-inner">
        <div className="how-s4-content-container">
          <div className="section-title-box" style={{ width: "fit-content" }}>
            <div className="section-title-text">Hecto Labs AI Solution</div>
            <div className="section-description-text">
              No Code, Click Only, All Workflow. <br />
              데이터 준비, 딥러닝 개발 및 배포의 전 과정을 <br /> 원스톱으로
              제공합니다.
            </div>
          </div>
          <div className="s4-right-content">
            <div className="s4-right-content-item">
              <img src="/asset/images/How/checkData.svg" alt="" />
              <div className="s4-right-content-item-textbox">
                <span className="s4-right-content-item-text1">데이터 관리</span>
                <span className="s4-right-content-item-text2">
                  방대한 학습 데이터를 웹에서 편리하게 관리
                </span>
              </div>
            </div>
            <div className="s4-right-content-item">
              <img src="/asset/images/How/label.svg" alt="" />
              <div className="s4-right-content-item-textbox">
                <span className="s4-right-content-item-text1">오토 라벨링</span>
                <span className="s4-right-content-item-text2">
                  자체 어노테이션 툴로 쉽고 빠르게 라벨링 완료
                </span>
              </div>
            </div>
            <div className="s4-right-content-item">
              <img src="/asset/images/How/aichip.svg" alt="" />
              <div className="s4-right-content-item-textbox">
                <span className="s4-right-content-item-text1">
                  딥러닝 모델 선택
                </span>
                <span className="s4-right-content-item-text2">
                  딥러닝 모델 스토어에서 최신 (SOTA) 딥러닝 모델 선택
                </span>
              </div>
            </div>
            <div className="s4-right-content-item">
              <img src="/asset/images/How/pencil.svg" alt="" />
              <div className="s4-right-content-item-textbox">
                <span className="s4-right-content-item-text1">학습</span>
                <span className="s4-right-content-item-text2">
                  모델만 선택하면 어떤 데이터든 트레이닝 가능
                </span>
              </div>
            </div>
            <div className="s4-right-content-item">
              <img src="/asset/images/How/api.svg" alt="" />
              <div className="s4-right-content-item-textbox">
                <span className="s4-right-content-item-text1">API 배포</span>
                <span className="s4-right-content-item-text2">
                  최적의 AI를 현장에 바로 적용
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="section-content-box">
          <div className="Hecto-Labs-AI-Solution">
            <div className="solution-text-box">
              <span className="ai-solution-text1">
                최적의 AI를 위한 <br />
                동시 학습 결과 비교
              </span>
              <span className="ai-solution-text2">
                여러 모델의 성능 지표를 한 눈에 확인한 후 <br />
                최적의 AI를 골라 사용할 수 있습니다.
              </span>
            </div>
            <img src="/asset/images/How/s4.svg" alt="" className="how-s4-img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section041;
